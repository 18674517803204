import { graphql } from 'gatsby';
import { applyPaddingCSS } from '@utils/generatePadding';
import RichText from '@components/RichText/RichText';
import {
  StyledColumnBlock,
  StyledColumnBlockItem,
  StyledColumnBlockContainer,
} from './ColumnBlock.styles';

const ColumnBlock = ({ elements }: Queries.ColumnBlockFragment) => {
  const { theme__color, column_items, options } = elements;
  const color = theme__color?.value;
  const hasSeparators = options?.value?.includes('has_separators');

  return (
    <StyledColumnBlock css={applyPaddingCSS(elements)}>
      <StyledColumnBlockContainer hasSeparators={hasSeparators} color={color}>
        {column_items.value.map((item, index) => {
          return (
            <StyledColumnBlockItem key={item.system.id}>
              <RichText {...item.elements.content} customResolveDomNode={() => {}} />
            </StyledColumnBlockItem>
          );
        })}
      </StyledColumnBlockContainer>
    </StyledColumnBlock>
  );
};

export const fragment = graphql`
  fragment ColumnBlock on kontent_item_column_block {
    __typename
    elements {
      ...Theme
      ...Padding
      column_items {
        value {
          system {
            id
          }
          ...SharedContent
        }
      }
      options {
        value
      }
    }
  }
`;

export default ColumnBlock;
