/* eslint-disable react/jsx-no-target-blank */
import { graphql } from 'gatsby';
import { ReactNode } from 'react';
import { ExternalLinkIcon } from '../Link.styles';

interface ExternalLinkProps extends Queries.LinkExternalFragment {
  children: ReactNode;
  title: string;
  className?: string;
}

const ExternalLink = ({ children, title, elements, className }: ExternalLinkProps) => {
  const href = elements.external_url.value;
  const sonarSourceRegexp = new RegExp(/https:\/\/?(www\.)?sonarsource.com/);
  const referrerPolicy = sonarSourceRegexp.exec(href) ? 'unsafe-url' : undefined;
  return (
    <a
      href={href}
      className={className}
      target="_blank"
      rel="noopener"
      title={title}
      referrerPolicy={referrerPolicy}
    >
      {children}
      <ExternalLinkIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.5 0.75C9.5 1.16562 9.83438 1.5 10.25 1.5H13.4406L6.46875 8.46875C6.175 8.7625 6.175 9.2375 6.46875 9.52812C6.7625 9.81875 7.2375 9.82187 7.52812 9.52812L14.4969 2.55938V5.75C14.4969 6.16563 14.8313 6.5 15.2469 6.5C15.6625 6.5 15.9969 6.16563 15.9969 5.75V0.75C15.9969 0.334375 15.6625 0 15.2469 0H10.25C9.83438 0 9.5 0.334375 9.5 0.75ZM2.25 1C1.00625 1 0 2.00625 0 3.25V13.75C0 14.9937 1.00625 16 2.25 16H12.75C13.9937 16 15 14.9937 15 13.75V9.75C15 9.33438 14.6656 9 14.25 9C13.8344 9 13.5 9.33438 13.5 9.75V13.75C13.5 14.1656 13.1656 14.5 12.75 14.5H2.25C1.83438 14.5 1.5 14.1656 1.5 13.75V3.25C1.5 2.83437 1.83438 2.5 2.25 2.5H6.25C6.66563 2.5 7 2.16563 7 1.75C7 1.33438 6.66563 1 6.25 1H2.25Z" />
      </ExternalLinkIcon>
    </a>
  );
};

export default ExternalLink;

export const fragment = graphql`
  fragment LinkExternal on kontent_item_link_action_external {
    __typename
    elements {
      external_url {
        value
      }
    }
  }
`;
