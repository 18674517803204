import { graphql, Link } from 'gatsby';
import { ImageElement } from '@kontent-ai/gatsby-components';
import { StyledHeader } from './Header.styles';

const Header = ({ headerElements }: Queries.HeaderHomepageFragment) => {
  const { logo } = headerElements;

  return (
    <StyledHeader>
      <Link to="/" title="Home">
        <ImageElement
          alt={logo.value.description ?? ''}
          loading="eager"
          objectFit="contain"
          objectPosition="left"
          image={{
            height: logo.value.height!,
            image_id: logo.value.url,
            url: logo.value.url,
            width: logo.value.width!,
          }}
          title={logo.value.description ?? ''}
        />
      </Link>
    </StyledHeader>
  );
};

export default Header;

export const fragment = graphql`
  fragment HeaderHomepage on kontent_item_header_homepage {
    __typename
    system {
      codename
    }
    headerElements: elements {
      logo {
        value {
          url
          description
          width
          height
        }
      }
    }
  }
`;
