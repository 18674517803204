import { graphql } from 'gatsby';
import RichText from '@components/RichText/RichText';
import { TabButtonWrapper, TabWrapper, TabContentWrapper, TabButton, TabIcon } from './Tabs.styles';
import { useState } from 'react';

const Tabs = ({ tabs }: { tabs: Queries.TabsFragment['elements']['tabs'] }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs.value[0].id);

  const tabButtons = tabs.value.map((tab) => {
    const title = tab.elements.title?.value?.toUpperCase();
    const icon = tab.elements.icon?.value?.[0];
    const id = tab.id;
    const isTabActive = activeTab === id;

    return (
      <TabButton
        key={id}
        isActiveTab={isTabActive}
        onClick={() => setActiveTab(id)}
        role="tab"
        id={`tab-${id}`}
        aria-controls={`tabpanel-${id}`}
        aria-selected={isTabActive}
      >
        {icon && <TabIcon src={icon.url} alt={icon.description ?? undefined} />}
        {title && <h3>{title}</h3>}
      </TabButton>
    );
  });

  const tabContents = tabs.value.map((tab) => {
    const tabDescription = tab.elements.description;
    const id = tab.id;
    const isTabActive = activeTab === id;

    return (
      <TabContentWrapper
        key={tab.id}
        isActiveTab={isTabActive}
        id={`tabpanel-${id}`}
        role="tabpanel"
        aria-labelledby={`tab-${id}`}
      >
        <RichText {...tabDescription} />
      </TabContentWrapper>
    );
  });

  return (
    <TabWrapper>
      <TabButtonWrapper role="tablist">{tabButtons}</TabButtonWrapper>
      {tabContents}
    </TabWrapper>
  );
};

export default Tabs;

export const fragment = graphql`
  fragment Tabs on kontent_item_tabs {
    __typename
    elements {
      tabs {
        value {
          elements {
            description {
              images {
                description
                height
                url
                image_id
                width
              }
              links {
                url_slug
                type
                link_id
                codename
              }
              value
              modular_content {
                ... on kontent_item {
                  system {
                    codename
                    type
                  }
                }
                ...AnchorHeading
                ...Callout
                ...CodeSnippet
                ...Collapsible
                ...Iframe
                ...Video
                ...Image
                ...Table
              }
            }
            icon {
              value {
                url
                description
              }
            }
            title {
              value
            }
          }
          id
        }
      }
    }
  }
`;
