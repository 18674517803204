import { graphql, Link as GatsbyLink } from 'gatsby';
import { ReactNode } from 'react';
import useGlobalData from '@hooks/useGlobalData';

interface InternalLinkProps extends Queries.LinkInternalFragment {
  children: ReactNode;
  title: string;
  className?: string;
}

const InternalLink = ({ children, title, elements, className }: InternalLinkProps) => {
  const { getInternalLink } = useGlobalData();
  const internalLinkURL = getInternalLink({
    id: elements.page.value.system.id,
  });

  const query = elements?.query?.value;
  const queryValue = query ? `?${query}` : '';

  const hash = elements?.hash?.value?.elements.url_slug.value;
  const hashValue = hash ? `#${hash}` : '';

  return (
    <GatsbyLink className={className} to={internalLinkURL + queryValue + hashValue} title={title}>
      {children}
    </GatsbyLink>
  );
};

export default InternalLink;

export const fragment = graphql`
  fragment LinkInternal on kontent_item_link_action_internal {
    __typename
    elements {
      page {
        value {
          ... on kontent_item_page {
            system {
              id
            }
          }
        }
      }
      hash {
        value {
          ...AnchorHeading
        }
      }
      query {
        value
      }
    }
  }
`;
