import tw, { css, styled } from 'twin.macro';

export const TabWrapper = styled.div`
  ${tw`mt-12 mb-6`}
`;

export const TabButtonWrapper = styled.div`
  ${tw`flex`};
`;

export const TabContentWrapper = styled.div(({ isActiveTab }: { isActiveTab: boolean }) => [
  !isActiveTab && tw`hidden`,
  tw`border border-b-secondary-s2-blue`,
  tw`p-[16px]`,
  css`
    & > * {
      ${tw`last:mb-0`};
    }
  `,
]);

export const TabButton = styled.button(({ isActiveTab }: { isActiveTab: boolean }) => [
  tw`flex`,
  tw`items-center`,
  tw`justify-center`,
  tw`flex-col`,
  tw`py-[16px]`,
  tw`px-[24px]`,
  tw`w-full`,
  tw`font-saira`,
  tw`font-bold`,
  tw`hover:bg-neutrals-n3`,
  tw`hover:text-neutrals-n9`,
  css`
    h3 {
      ${tw`text-center text-xs`};
      ${tw`mb-0`};
      ${tw`[line-height: 16px]`};
    }
  `,
  isActiveTab && tw`bg-secondary-s2-blue`,
  isActiveTab && tw`hover:bg-secondary-s2-blue`,
  isActiveTab &&
    css`
      h3 {
        ${tw`text-neutrals-n1`};
      }
    `,
]);

export const TabIcon = styled.img`
  ${tw`[width: 32px]`};
  ${tw`pb-[8px]`};
`;
