export const LATEST_SLUG = 'latest';
export enum PRODUCTS {
  sonarcloud = 'sonarcloud',
  sonarlint = 'sonarlint',
  sonarqube = 'sonarqube',
}
export const PRODUCT_LOGO_WIDTH = {
  [PRODUCTS.sonarcloud]: 125,
  [PRODUCTS.sonarlint]: 76,
  [PRODUCTS.sonarqube]: 115,
};
